import { ConstantsService } from 'src/app/services/_core/constants/constants.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { PinCheck } from '@ionic-native/pin-check/ngx';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable, HostListener } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { first, map, catchError } from 'rxjs/operators';
import { resolve } from 'q';
import { State } from 'src/app/models/_core/state';
import * as moment from 'moment';

/**
 * ID: bh-helper-utilities-service
 * Name: BH Helper Utilities Service
 * Description: Library of commonly used methods for a variety of purposes.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Removed console.log
 * 2021-10-08 - MW - v3: Improved last name calc
 */

@Injectable({
  providedIn: 'root'
})
export class HelperUtilitiesService {
  env = environment;
  replacementTerms = [
    { term: 'from', replaceWith: 'fr0m' },
    { term: 'From', replaceWith: 'Fr0m' },
  ];


  constructor(
    private platform: Platform,
    private http: HttpClient,
    private pinCheck: PinCheck,
    private notificationsService: NotificationsService,
    private constants: ConstantsService
  ) { }

  /** Check if current device is NOT a web browser
   * @return Returns a TRUE if current device is NOT a browser
   */
  isNotBrowser(): boolean {
    console.log('platforms:', this.platform.platforms());
    if ((this.platform.is('cordova') || this.platform.is('capacitor'))) {
      return true;
    } else {
      return false;
    }
  }

  getHealthCheck() {
    const url = environment.apiUrl + '/healthcheck';
    return this.http.get(url).pipe(
      map((data: any) => {

        return data;
      }),
      catchError(err => {
        // user notifications
        this.notificationsService.handleError(err, 'helper-utilities-service.getHealthCheck(): ' + url);
        return of(err);
      })
    );
  }

  async isPinReady(): Promise<boolean> {
    return await new Promise(async val => {
      if (this.isNotBrowser()) {
        await this.pinCheck.isPinSetup()
          .then(
            (data) => {
              console.log('pin is setup.', val);
              resolve(true);
            },
            (err) => {
              console.log('pin not setup.', val);
              resolve(false);
            }
          );
      } else {
        // web browser; assume true
        // console.log('web browser detected during pin check');
        resolve(true);
      }
    });

  }

  convertBooleanAsYesNoText(boolValue) {
    if (boolValue) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  cleanPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      return phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').trim();
    } else {
      return '';
    }
  }

  cleanSSN(ssn) {
    if (ssn) {
      const re = /\-/gi;
      return ssn.replace(re, '').trim();
    } else {
      return '';
    }
  }

  formatSsn(value) {
    if (value && value.length === 9) {
      return value.substr(0, 3) + '-' + value.substr(3, 2) + '-' + value.substr(5, 4);
    } else {
      return '';
    }
  }

  formatPhone(value) {
    if (value && value.length === 10) {
      return '(' + value.substr(0, 3) + ') ' + value.substr(3, 3) + '-' + value.substr(6, 4);
    }
    return value;
  }

  getStateName(value) {
    const states: State[] = this.constants.states.filter((val) => val.stateCode === value);
    if (states && states.length > 0) {
      return states[0].stateName;
    } else if (!value || value === '') {
      return '';
    } else {
      return '(Unknown State Code)';
    }
  }

  formatDate(value, parseFormat) {
    if (parseFormat) {
      return this.checkForInvalidDate(value, moment(value, parseFormat).format('M/D/YYYY'));
    }
    if (value && value.length === 8 && value.indexOf('-') === -1) {
      // yyyyMMdd format
      return this.checkForInvalidDate(value, moment(value, 'YYYYMMDD').format('M/D/YYYY'));
    } else if (value && value.length === 10 && value.indexOf('-') === 4) {
      // yyyy-MM-dd format
      return this.checkForInvalidDate(value, moment(value, 'YYYY-MM-DD').format('M/D/YYYY'));
    } else if (value && value.length === 12 && value.indexOf('-') === -1) {
      // yyyyMMddHHmm format
      return this.checkForInvalidDate(value, moment(value, 'YYYYMMDDHHmm').format('M/D/YYYY H:mm'));
    } else if (value && value.length === 14 && value.indexOf('-') === -1) {
      // yyyyMMddHHmmss format
      return this.checkForInvalidDate(value, moment(value, 'YYYYMMDDHHmmss').format('M/D/YYYY H:mm:ss'));
    } else if (value && value.length > 24 && value.indexOf('T') > -1) {
      return this.checkForInvalidDate(value,  moment(value).format('M/D/YYYY H:mm:ss'));
    } else {
      // Unexpected format, return value as is
      return value;
    }

  }

  checkForInvalidDate(value, parsedDate) {
    return (parsedDate !== 'Invalid date') ? parsedDate : value;
  }

  convertNullToEmptyString(value) {
    // console.log('Converting value ', value);
    if (typeof value === 'undefined' || value === null) {
      return '';
    } else {
      return value;
    }
  }

  encodeEmail(email) {
    return encodeURI(email).replace('#', '%23').replace('&', '%26').replace('?', '%3F');
  }

  getFirstName(fullName) {
    return (fullName && fullName.indexOf(',') > -1) ? fullName.split(',')[1].trim() : 
      (fullName && fullName.indexOf(' ') > -1) ? fullName.split(' ')[0].trim() : fullName;
  }

  getLastName(fullName) {
    return (fullName && fullName.indexOf(',') > -1) ? fullName.split(',')[0].trim() : 
      (fullName && fullName.indexOf(' ') > -1) ? fullName.split(' ')[1].trim() : fullName;
  }

  setAddressBarUrl(url) {
    window.history.pushState(null, this.env.appDescription + ' - Baystate Health', url);
  }

  cleanUserInput(input: string) {
    let newInput = '';
    for (const t of this.replacementTerms) {
      const regex = new RegExp(t.term, 'g');
      newInput = input.replace(regex, t.replaceWith);
    }
    return newInput;
  }

  presentUserInput(input: string) {
    let newInput = '';
    for (const t of this.replacementTerms) {
      const regex = new RegExp(t.replaceWith, 'g');
      newInput = input.replace(regex, t.term);
    }
    return newInput;
  }
}
