import { ModalController, PopoverController } from "@ionic/angular";
import { BhInputTextEditorPage } from "./../bh-input-text-editor/bh-input-text-editor.page";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { Diagnosis } from "./../../../models/diagnosis";
import { DiagnosesService } from "./../../../services/diagnoses/diagnoses.service";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
  ControlContainer,
} from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { SelectOption } from "src/app/models/_core/select-option";
import { BhInputType } from "src/app/models/_core/input-type";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import * as moment from "moment";
import { BhFormQuestionTextComponent } from "../../bh-form-question-text/bh-form-question-text.component";

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: "M/D/YYYY",
  },
  display: {
    dateInput: "M/D/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
/**
 * ID: bh-input
 * Name: BH Input
 * Description: A multi-functional input component that includes UX standardization and styling.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v2: Implemented text editor for ion-textarea
 * 2021-06-01 - MW - v1: Initial dev
 */
@Component({
  selector: "bh-input",
  templateUrl: "./bh-input.component.html",
  styleUrls: ["./bh-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BhInputComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],
})
export class BhInputComponent implements ControlValueAccessor, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() type: BhInputType;
  @Input() label = "";
  @Input() value = "";
  @Input() color = "";
  @Input() placeholder = "";
  @Input() formControlName = "";
  @Input() selectOptions: SelectOption[] = [];
  @Input() selectLabelProperty = "label";
  @Input() selectValueProperty = "value";
  @Input() includeDefaultBlankValue = false;
  @Input() readonly = false;
  @Input() viewOnly = false;
  @Input() viewOnlyValue = "";
  @Input() maxlength;
  @Input() index = -1;
  @Input() showSeparatorLine = false;
  @Input() indentLeft = false;
  @Input() iconName = null;
  @Input() iconColor = "primary";
  @Input() min;
  @Input() max;
  @Input() required;
  @Input() interface = "popover";
  @Input() validationMessages: any;
  @Input() submitAttempted = false;
  @Input() helperText: string;
  @Input() allowTagAdding = false;
  @Input() useTextEditor;
  @Input() hideHeader = false;
  @Input() categoryLevel: string = "";
  @Input() categoryIndex: number = 0;
  @Input() isChargeReport: boolean = false;
  @Input() isChargeReportMode: boolean = false;
  @Input() disableBold = false;
  @Input() selectedMulti = [];
  @Output() valueChangeEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() selectDxEvent = new EventEmitter();
  @Output() selectTagEvent = new EventEmitter();
  @Output() addTagEvent = new EventEmitter();
  @Output() checkboxStateEvent = new EventEmitter();
  onChange;
  onTouched;
  showPlaceholder = true;
  diagnoses: any[] = [];
  selectedOptions: SelectOption[] = [];
  lookupTimer = null;
  lockedTerm = "";
  viewOnlySelectLabel = "";
  viewOnlyChecklistLabel = "";

  isMobile = false;
  showingTextEditor = false;
  parsedSelectOptions: SelectOption[] = [];

  parsedMultiSelectOptions: SelectOption[] = [];

  constructor(
    public controlContainer: ControlContainer,
    private diagnosesService: DiagnosesService,
    private notifications: NotificationsService,
    private modalCtrl: ModalController,
    private popoverController: PopoverController
  ) {
    if (this.type === "date" && this.value) {
      const parsedDate = moment(this.value, "M/D/YYYY");
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    const width = event.target.innerWidth;
    this.checkViewportSize(width);
  }

  checkViewportSize(width) {
    if (width > 767) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const width = window.innerWidth;
    this.checkViewportSize(width);
    if (
      ("type" in changes ||
        "viewOnlyValue" in changes ||
        "selectOptions" in changes) &&
      this.type === "select" &&
      this.viewOnlyValue
    ) {
      this.getLabelFromSelectOptions(this.viewOnlyValue);
    }
    if (
      ("type" in changes &&
        "viewOnlyValue" in changes &&
        "selectOptions" in changes) &&
      this.type === "ion-checklist" &&
      this.viewOnlyValue
    ) {
      //console.log('I am here!');
      this.getLabelFromCheckListOptions(this.viewOnlyValue);
    }
    if ("selectOptions" in changes) {
      this.parseSelectOptions(this.viewOnlyValue);
    }
    if (this.type === "ion-checklist") {
      this.parseMultiSelectOptions(this.value);
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  parseSelectOptions(value) {
    console.log('parseSelectOptions.value', value);
    if (this.selectOptions && this.selectOptions.length > 0) {
      this.parsedSelectOptions = [];
      for (const s of this.selectOptions) {
        const selOption: SelectOption = {
          label: s[this.selectLabelProperty],
          value: s[this.selectValueProperty],
          active: 1,
        };
        this.parsedSelectOptions.push(selOption);
      }
      const selectedItem: SelectOption = this.parsedSelectOptions.find(
        (a) => a.value && a.value.toString() === value.toString()
      );
      this.viewOnlySelectLabel =
        selectedItem !== undefined ? selectedItem.label : "";
      //console.log('bh-input: parseSelectOptions: ', this.selectOptions, this.parsedSelectOptions);
    }
  }

  parseMultiSelectOptions(value) {
    const splitValues = value.split("|").map((item) => item.trim());
    //console.log("splitValue", splitValues);
    this.formGroup.controls[this.formControlName].setValue(value);
    if (this.selectOptions && this.selectOptions.length > 0) {
      this.selectOptions = this.selectOptions.filter(so => so.active);
      this.selectOptions = this.selectOptions.map((so) => ({
        ...so,
        checked: splitValues.filter((sv) => sv === so.value).length > 0,
      }));
      //console.log("parseMultiSelectOption.selectOptions", this.selectOptions);
    }
  }

  parseDelimitedValue(value, selectedValue) {
    console.log("selectedValue", selectedValue, "value", value);

    for (let val of value) {
      if (val === selectedValue) {
        return true;
      } else {
        false;
      }
    }
  }

  getLabelFromSelectOptions(value) {
    if (this.selectOptions && this.selectOptions.length > 0) {
      const selectedItem: SelectOption = this.selectOptions.filter(
        (a) => a.value.toString() === value.toString()
      )[0];
      this.viewOnlySelectLabel = selectedItem ? selectedItem.label : "";
    }
  }

  getLabelFromCheckListOptions(value) {
    const splitValues = value.split("|").map((item) => item.trim());
    const joinedLabelsArray = [];
    //console.log('getLabelFromCheckListOptions.splitValue', splitValues);
    if (this.selectOptions && this.selectOptions.length > 0) {
      if (value.includes("|")) {
        value = value.replace(/\|/g, ", ");
      }
      const selectedOptions = [];
      for(const sv of splitValues) {
        const foundOption = this.selectOptions.find( so => so.value === sv);
        selectedOptions.push(foundOption)
      }
      //console.log('selectedOptions', selectedOptions);
      for(const so of selectedOptions) {
        if(so !== undefined) {
          joinedLabelsArray.push(so.label);
        }
      }
      this.viewOnlyChecklistLabel = joinedLabelsArray ? joinedLabelsArray.join(', ') : "";
    }
  }

  inputChanged(index, label, value) {
    const input = { index, label, value };
    this.valueChangeEvent.emit(input);
    this.showPlaceholder = value && value !== "";
  }

  blurInput(ev) {
    const value = this.formGroup.controls[ev].value;
    //console.log("Blur value", value);
    this.blurEvent.emit(value);
  }

  async showHelperTip(ev, helperText: string) {
    //console.log("helperText", helperText);

    const popover = await this.popoverController.create({
      component: BhFormQuestionTextComponent,
      event: ev,
      cssClass: "helper-text-popover",
      componentProps: {
        helperText: helperText,
      },
    });
    popover.onDidDismiss().then((data) => {
      if (data.data) {
        //this.calcScheduleProgress();
        //this.callOutConflictPresentModal = false;
      }
    });
    await popover.present();
  }

  searchDiagnoses(ev) {
    const term = this.formGroup.controls[this.formControlName].value;
    if (term && term !== "" && term !== this.lockedTerm) {
      this.lockedTerm = "";
      if (this.lookupTimer) {
        clearTimeout(this.lookupTimer);
        this.lookupTimer = null;
      }
      this.lookupTimer = setTimeout(async () => {
        try {
          const res = await this.diagnosesService
            .getDiagnosesByTerm(term)
            .toPromise();
          this.diagnoses = this.diagnosesService.parseDiagnoses(res);
        } catch (err) {
          console.log("Error occurred while searching for diagnosis", err);
        }
      }, 500);
    } else {
      this.diagnoses = [];
    }
  }

  closeDiagnosisSuggestions() {
    let timer = setTimeout(() => {
      this.diagnoses = [];
      timer = null;
    }, 300);
  }

  selectDiagnosis(dx: Diagnosis) {
    this.formGroup.controls[this.formControlName].setValue(dx.description);
    this.diagnoses = [];
    this.lockedTerm = dx.description;
    this.selectDxEvent.emit(dx);
  }

  async checkForTextEditor() {
    // console.log('checkForTextEditor: isMobile: ',  this.isMobile);
    if ((this.isMobile || this.useTextEditor) && !this.showingTextEditor) {
      this.showingTextEditor = true;
      const textarea = document
        .getElementById("ion-textarea-" + this.formControlName)
        .querySelector("textarea");
      textarea.blur();
      const modal = await this.modalCtrl.create({
        component: BhInputTextEditorPage,
        cssClass: "wide-modal super-wide",
        backdropDismiss: false,
        componentProps: {
          formControlName: this.formControlName,
          label: this.label,
          placeholder: this.placeholder,
          value: this.formGroup.controls[this.formControlName].value,
          readonly: this.readonly,
          maxlength: this.maxlength,
        },
      });

      modal.onDidDismiss().then((data) => {
        this.showingTextEditor = false;
        if (data.data.done) {
          this.value = data.data.value;
          this.formGroup.controls[this.formControlName].setValue(this.value);
          textarea.blur();
        }
      });

      return (await modal).present();
    }
  }

  selectColor(color) {
    console.log("Selected Color is", color);
    let buttonElements = document.querySelectorAll(
      "div.alert-radio-group button.colorselect"
    );
    for (let index = 0; index < buttonElements.length; index++) {
      let buttonElement = buttonElements[index];
      buttonElement.classList.remove("color-selected");
      if (buttonElement.classList.contains("color_" + color.slice(1, 7))) {
        buttonElement.classList.add("color-selected");
      }
    }
  }

  setColor(color) {
    console.log("Selected Color is");
  }

  makeChecked(ev, option) {
    option.checked = !option.checked;
    if(option.checked) {
      const findIndex = this.selectOptions.findIndex(
        (so) => so.qoSeq === option.qoSeq
      );
      this.selectOptions[findIndex] = option;
    } else {
      const index = this.selectOptions.findIndex(
        (m) => m.qoSeq === option.qoSeq
      );
      this.selectOptions[index].checked = false;
    }
    this.checkboxStateEvent.emit(this.selectOptions);
  }

  getCheckedList(ev, option) {
    console.log("event", ev, "option", option);
    if (ev.target.checked) {
      option.checked = true;
      const findIndex = this.selectOptions.findIndex(
        (so) => so.qoSeq === option.qoSeq
      );
      this.selectOptions[findIndex] = option;
     
      //this.selectedOptions.push(option);
    } else {
      const index = this.selectOptions.findIndex(
        (m) => m.qoSeq === option.qoSeq
      );
      this.selectOptions[index].checked = false;
    }
    //console.log("getCheckedList.selectedOptions", this.selectOptions);
    this.checkboxStateEvent.emit(this.selectOptions);
  }
}
